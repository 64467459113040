import React, {Component} from 'react';
import {RadioButton} from 'wix-ui-tpa/RadioButton';
import {RadioButton as RadioButtonCore} from 'wix-ui-core/radio-button';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';
import s from './SubscriptionPlan.scss';
import {SubscriptionPlanFrequency} from '../../../../graphql/queries-schema';
import classNames from 'classnames';
import {RadioButtonTheme} from 'wix-ui-tpa/dist/src/components/RadioButton/RadioButton';
import {classes} from './SubscriptionPlan.st.css';

export const enum SubscriptionPlanDataHooks {
  PLAN_ITEM = 'plan-item',
  NAME = 'plan-name',
  TAGLINE = 'plan-tagline',
  PRICE = 'plan-price',
  FREQUENCY = 'plan-frequency',
  DURATION = 'plan-duration',
  SELECTION = 'plan-selection',
  SELECTION_ICON = 'SELECTION_ICON',
}

export interface ISubscriptionPlan {
  id?: string;
  name: string;
  isOneTimePurchase: boolean;
  formattedPrice: string;
  price?: number;
  tagline?: string;
  frequency?: SubscriptionPlanFrequency;
  duration?: number;
}

export interface ISubscriptionPlanProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  plan: ISubscriptionPlan;
  planVariantFormattedPrice?: string;
  isSelected: boolean;
  accessibilityEnabled?: boolean;
  className?: string;
  onClick?(): void;
}

@withGlobalProps
@withTranslations('globals.texts')
export class SubscriptionPlan extends Component<ISubscriptionPlanProps> {
  private readonly getDurationTranslation = () => {
    const {duration} = this.props.plan;
    return duration > 1 || duration === null
      ? this.getPluralDurationTranslation()
      : this.getSingularDurationTranslation();
  };

  private readonly getSingularDurationTranslation = () => {
    const {t, plan} = this.props;

    switch (plan.frequency) {
      case 'DAY':
        return 'DAY_QA';
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
          frequencyUnitSingular: t('PRODUCT_PAGE_PLAN_MONTH'),
        });
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
          frequencyUnitSingular: t('PRODUCT_PAGE_PLAN_WEEK'),
        });
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_SINGULAR', {
          frequencyUnitSingular: t('PRODUCT_PAGE_PLAN_YEAR'),
        });
    }
  };

  private readonly getPluralDurationTranslation = () => {
    const {t, plan} = this.props;

    if (!plan.duration) {
      return t('PRODUCT_PAGE_PLAN_AUTORENEW');
    } else {
      switch (plan.frequency) {
        case 'DAY':
          return 'DAYS_QA';
        case 'MONTH':
          return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
            numberOfFrequencyUnits: plan.duration,
            frequencyUnitPlural: t('PRODUCT_PAGE_PLAN_MONTHS'),
          });
        case 'WEEK':
          return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
            numberOfFrequencyUnits: plan.duration,
            frequencyUnitPlural: t('PRODUCT_PAGE_PLAN_WEEKS'),
          });
        case 'YEAR':
          return t('PRODUCT_PAGE_PLAN_DURATION_DESCRIPTION_PLURAL', {
            numberOfFrequencyUnits: plan.duration,
            frequencyUnitPlural: t('PRODUCT_PAGE_PLAN_YEARS'),
          });
      }
    }
  };

  private getFrequencyTranslation(): string {
    const t = this.props.t;
    const {frequency} = this.props.plan;

    switch (frequency) {
      case 'DAY':
        return 'DAYLY_QA';
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_FREQUENCY_MONTHLY');
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_FREQUENCY_WEEKLY');
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_FREQUENCY_YEARLY');
    }
  }

  private readonly onClick = () => {
    const {onClick: onCompClicked} = this.props;
    onCompClicked && onCompClicked();
  };

  private readonly getRadioButtonIcon = (checked: boolean) => {
    const circleClass = classNames(s.circle, {[s.checkedCircle]: checked});
    return <span data-hook={SubscriptionPlanDataHooks.SELECTION_ICON} className={circleClass} />;
  };

  public render(): JSX.Element {
    const {
      isSelected,
      className,
      planVariantFormattedPrice,
      globals: {product, experiments, accessibilityEnabled},
    } = this.props;
    const {name, tagline, formattedPrice, frequency} = this.props.plan;
    const subscriptionPlanClasses = classNames(s.container);

    const subscriptionContent = (
      <div>
        <div data-hook={SubscriptionPlanDataHooks.NAME} className={s.name}>
          {name}
        </div>
        {!!tagline && tagline.trim() && (
          <div className={s.subtitle} data-hook={SubscriptionPlanDataHooks.TAGLINE}>
            {tagline}
          </div>
        )}
        <div>
          <span data-hook={SubscriptionPlanDataHooks.PRICE} className={s.price}>
            {planVariantFormattedPrice || formattedPrice}
          </span>
          {!!frequency && (
            <span className={s.frequency} data-hook={SubscriptionPlanDataHooks.FREQUENCY}>
              {this.getFrequencyTranslation()}
            </span>
          )}
          {
            <span className={s.duration} data-hook={SubscriptionPlanDataHooks.DURATION}>
              {this.getDurationTranslation()}
            </span>
          }
        </div>
      </div>
    );

    if (experiments.isSubscriptionPlanTpaRadioButton) {
      return (
        <div className={className}>
          <RadioButton
            value={name}
            onChange={this.onClick}
            data-hook={SubscriptionPlanDataHooks.SELECTION}
            checked={isSelected}
            aria-label={name}
            theme={RadioButtonTheme.Box}
            name={product.id}
            withFocusRing={accessibilityEnabled}
            className={classes.subscriptionPlanRadioButton}>
            <div
              data-hook={SubscriptionPlanDataHooks.PLAN_ITEM}
              className={subscriptionPlanClasses}
              onClick={this.onClick}>
              {subscriptionContent}
            </div>
          </RadioButton>
        </div>
      );
    } else {
      const subscriptionPlanClassesOld = classNames(s['old-container'], className, {[s.selected]: isSelected});
      return (
        <div
          data-hook={SubscriptionPlanDataHooks.PLAN_ITEM}
          className={subscriptionPlanClassesOld}
          onClick={this.onClick}>
          <span className={s.radioButton}>
            <RadioButtonCore
              data-hook={SubscriptionPlanDataHooks.SELECTION}
              checked={isSelected}
              checkedIcon={this.getRadioButtonIcon(true)}
              uncheckedIcon={this.getRadioButtonIcon(false)}
              aria-label={name}
              name={product.id}
            />
          </span>
          {subscriptionContent}
        </div>
      );
    }
  }
}
